import 'tailwindcss/tailwind.css';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { apolloClient } from '@/utils/apollo-initializer';
import { SentryErrorBoundary } from '@/utils/sentry';
var mountReactComponents = function (components) {
    var placeholders = document.querySelectorAll('[data-react-component]');
    placeholders.forEach(function (item) {
        var root = createRoot(item);
        var componentName = item.dataset.reactComponent;
        if (componentName in components) {
            var props = JSON.parse(item.dataset.reactProps || '{}');
            var element = React.createElement(components[componentName], props);
            root.render(<React.StrictMode>
          <SentryErrorBoundary>
            <ApolloProvider client={apolloClient}>
              <ChakraProvider>{element}</ChakraProvider>
            </ApolloProvider>
          </SentryErrorBoundary>
        </React.StrictMode>);
        }
    });
};
export var bootstrap = function (components) {
    window.addEventListener('turbolinks:load', function () {
        mountReactComponents(components);
    });
    window.addEventListener('DOMContentLoaded', function () {
        mountReactComponents(components);
    });
};
